import React from 'react'
import CourseOfferings from '../common/courseOfferingsPanel'

const Pricing = () => (
  <div>
    <CourseOfferings />
  </div>
)

export default Pricing
